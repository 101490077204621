<template>
  <div class="auth">
    <Header :title="topTitle" :back="true"/>
    <div class="box-items" v-if="step=='step1'">
      <div class="title-pwd">为保护您的账户安全，绑定手机或者邮箱需要验证您的身份信息，请输入您的6位数字交易密码</div>
      <!-- <div v-if="tips" class="error-tips"><i class="el-icon-error"></i>{{tips}}</div> -->
      <div style="margin:2% 4%">
        <Password ref="passwordInputRef"
            mask
            :pwdd.sync="password1"
            @complete="onPasswordInputComplete1"/>
      </div>
      <div class="form-items"><button class="wid-btn" :disabled="disabledShow" style="width:96%" @click="handleAuth">验证身份</button></div>
    </div>
    <div class="box-items" style="position:relative"  v-if="step=='step2'">
      <div class="code-btn theme-color" @click="getCode">{{codeText}}</div>
      <el-input
        class="form-items"
        :placeholder="msgTitle"
        v-model="name"
        @focus="tips=''"
        :prefix-icon="icon"
        >
      </el-input>
      <el-input
        class="form-items"
        @focus="tips=''"
        placeholder="请输入验证码"
        v-model="code"
        prefix-icon="el-icon-postcard"
        clearable>
      </el-input>
      <div v-if="tips" class="error-tips"><i class="el-icon-error"></i>{{tips}}</div>
      <div class="form-items"><button class="wid-btn" style="width:96%" @click="handleBD">确认绑定</button></div>
    </div>
    
  </div>
</template>
<script>
import Header from '@/components/Header'
import Password from '@/components/Password/Index'
export default {
  components: {
    Header,
    Password
  },
  data(){
    return{
      step:'step1',
      topTitle:'绑定手机',
      type:this.$route.query.type,
      tips:'',
      password1:'',
      disabledShow:true,
      disabledCode:false,
      msgTitle:'请输入新的手机号',
      icon:'el-icon-mobile',
      code:'',
      name:'',
      codeText:'获取验证码',
      date:60,
      timer:null
    }
  },
  created(){
    if(this.type=='tel'){
      this.msgTitle ="请输入新的手机号"
      this.icon="el-icon-mobile"
      this.topTitle='绑定手机'
    }else if(this.type=='email'){
      this.msgTitle ="请输入新的邮箱"
      this.icon="el-icon-c-scale-to-original"
      this.topTitle='绑定邮箱'
    }
  },
  methods:{
    handleAuth(){
      // 校验验证码
      let params = {
        type:'tradepassword',
        tradepassword:this.password1
      }
      this.$store
        .dispatch('auth/checkscodes',params)
        .then(res => {
          if(res.msg=='交易密码正确'){
            this.step = 'step2'
          }
        }) 
    },
    handleBD(){
      let params = {
        type:this.type,
        account:'my',
        verify:this.code
      }
      if(this.type=='tel'){
        params.tel = this.name
      }else{
        params.email = this.name
      }
      this.$store
        .dispatch('auth/updateUser',params)
        .then(() => {
          this.$router.push({name:"secure"})
        })
    },
    onPasswordInputComplete1(){
      this.disabledShow = false
    },
    // 获取验证码
    getCode() {
      let that = this
      if(this.disabledCode){
        return false
      }
      if (this.name === '') {
        this.tips=this.msgTitle
      } else {
        that.timer = null
        let params = {
          account:this.name,
          action:'reg'
        }
        this.$store
          .dispatch('auth/getcodes',params)
          .then(res => {
            if (res.code === 0) {
              this.disabledCode = true
              if (that.date == 60) {
                console.log(this.date)
                that.timer = setInterval(() => {
                  this.date--
                  this.codeText = this.date + 's'
                  if (this.date == 0) {
                    this.date = 60
                    this.disabledCode = false
                    this.codeText = '获取验证码'
                    clearInterval(that.timer)
                  }
                }, 1000)
              }
            } else {
              this.disabledCode = false
              this.date = 60
              this.codeText = '获取验证码'
              clearInterval(that.timer)
            }
          })
          .catch(err => {
            this.text = err.body.msg
          })
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.auth{
  .title-pwd{
    // background: #ffffff;
    padding: 1rem 5%;
    color: #464646;
    line-height: 2;
    font-size: .9rem;
  }
  .box-items{
    margin-top:2.5%
  }
  .form-items{
    margin: 1rem auto 0px;
  }
  .checkbox-box{
    font-size: .8rem;
    color: #464646;
    margin-top:3px;
   
  }
}
.draw-box{
  padding:2% 3%;
  :not(:first-child)div{
    margin-bottom:10px;
    margin-left:5%;
    margin-right:5%;
    
  }
  .close-icon{
    text-align: right;
    font-size: 1.5rem;
    margin-bottom:0px !important
  }
}
.draw-title{
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #989898;
  text-align: center;
  padding-top:5px;
  padding-bottom:5px
}
.code-btn{
  position: absolute;
  right: 20px;
  top:43px;
  z-index: 2;
  font-size: .8rem;
  cursor: pointer;
}
</style>