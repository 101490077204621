var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "auth" },
    [
      _c("Header", { attrs: { title: _vm.topTitle, back: true } }),
      _vm.step == "step1"
        ? _c("div", { staticClass: "box-items" }, [
            _c("div", { staticClass: "title-pwd" }, [
              _vm._v(
                "为保护您的账户安全，绑定手机或者邮箱需要验证您的身份信息，请输入您的6位数字交易密码"
              ),
            ]),
            _c(
              "div",
              { staticStyle: { margin: "2% 4%" } },
              [
                _c("Password", {
                  ref: "passwordInputRef",
                  attrs: { mask: "", pwdd: _vm.password1 },
                  on: {
                    "update:pwdd": function ($event) {
                      _vm.password1 = $event
                    },
                    complete: _vm.onPasswordInputComplete1,
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "form-items" }, [
              _c(
                "button",
                {
                  staticClass: "wid-btn",
                  staticStyle: { width: "96%" },
                  attrs: { disabled: _vm.disabledShow },
                  on: { click: _vm.handleAuth },
                },
                [_vm._v("验证身份")]
              ),
            ]),
          ])
        : _vm._e(),
      _vm.step == "step2"
        ? _c(
            "div",
            { staticClass: "box-items", staticStyle: { position: "relative" } },
            [
              _c(
                "div",
                {
                  staticClass: "code-btn theme-color",
                  on: { click: _vm.getCode },
                },
                [_vm._v(_vm._s(_vm.codeText))]
              ),
              _c("el-input", {
                staticClass: "form-items",
                attrs: { placeholder: _vm.msgTitle, "prefix-icon": _vm.icon },
                on: {
                  focus: function ($event) {
                    _vm.tips = ""
                  },
                },
                model: {
                  value: _vm.name,
                  callback: function ($$v) {
                    _vm.name = $$v
                  },
                  expression: "name",
                },
              }),
              _c("el-input", {
                staticClass: "form-items",
                attrs: {
                  placeholder: "请输入验证码",
                  "prefix-icon": "el-icon-postcard",
                  clearable: "",
                },
                on: {
                  focus: function ($event) {
                    _vm.tips = ""
                  },
                },
                model: {
                  value: _vm.code,
                  callback: function ($$v) {
                    _vm.code = $$v
                  },
                  expression: "code",
                },
              }),
              _vm.tips
                ? _c("div", { staticClass: "error-tips" }, [
                    _c("i", { staticClass: "el-icon-error" }),
                    _vm._v(_vm._s(_vm.tips)),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "form-items" }, [
                _c(
                  "button",
                  {
                    staticClass: "wid-btn",
                    staticStyle: { width: "96%" },
                    on: { click: _vm.handleBD },
                  },
                  [_vm._v("确认绑定")]
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }